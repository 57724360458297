import * as React from "react"
import { graphql } from "gatsby"

import LayoutEnglish from "../components/layoutEnglish"
import Seo from "../components/seo"

const IndexPage = ({ data }) => (
  <LayoutEnglish>
    <Seo title="City Stories" />
    <h1>EN City Stories</h1>
    <p>EN Hier kommt nen Infotext über das Projekt hin.</p>
  </LayoutEnglish>
)

export const query = graphql`
  query 
  { 
    allMdx {
      nodes {
        frontmatter {
          title
        }
        id
        body
        slug
      }
    }
  }
`

export default IndexPage
